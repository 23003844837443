import React from "react"
import "./about.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import CTA from "../components/cta"
import Logo from "../images/board-logo.svg"
import Shape from "../components/shape/shape"
import ShapeTeam from "../components/shapeTeam/shapeTeam"
import { WePioneer } from "../components/wepioneer/wepioneer"

const About = () => (
  // NEW DESIGN
  <Layout>
    <SEO title="About" />
    <WePioneer />
    <ShapeTeam />
  </Layout>
)

export default About

import React, { useEffect, useState } from "react"
import "./memo.scss"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Modal from "react-bootstrap/Modal"
import Advisor from "../../../../images/home/overcoming/advisor.jpg"
import Share from "../../../../images/about/shareicon.svg"
import ShareSafari from "../../../../images/about/share3.png"
import Shape from "../../../../images/shape2.svg"
import Cross from "../../../../images/home/overcoming/cross.svg"
import { memoContent } from "./helper"
import { isIOS, isSafari } from "react-device-detect"

const Memo = ({ openMemo, setOpenMemo }) => {
  const [isSafariBrow, setIsSafari] = useState(false)
  useEffect(() => {
    setIsSafari(isSafari)
  }, [isSafari])

  const copyLink = async () => {
    if (typeof window === "undefined") return
    console.log(window.location)
    let baseUrl = window?.location?.origin

    navigator.clipboard.writeText(`${baseUrl}/about/#memo`)
    let shareDetails = {
      url: `${baseUrl}/about/#memo`,
      title: "SHAPE",
      text: "",
    }
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => console.log("Content Shared Successfully!"))
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`)
      }
    } else {
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      )
    }
  }
  let tooltipElem = () => {
    return (
      <div className="tooltipWrapperMemo">
        <div className="tooltipFlex">
          <button id="copy-btn-modal" onClick={copyLink}>
            Share
          </button>
          <img
            className="closeTooltip"
            src={Cross}
            alt="Close"
            onClick={() => {
              document.body.click()
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <Modal
      size="xl"
      dialogClassName="modal-90w"
      contentClassName="modalMemoContent"
      show={openMemo}
      onHide={() => {
        setOpenMemo(false)
      }}
    >
      <img
        className="closeModal"
        src={Cross}
        alt="Close"
        onClick={() => setOpenMemo(false)}
      />

      <Modal.Body className="memoWrapper">
        <div className="memoLogo">
          <img src={Shape} alt="SHAPE" />
        </div>
        <div className="memoHeader">
          <h5>MEMO</h5>
          <div className="memoAdvisorFlex">
            <div className="memoImage">
              <img src={Advisor} alt="Advisor" />
            </div>
            <div className="memoName">
              <p>From: Nicola Thompson</p>
              <p>SHAPE Board Advisor</p>
            </div>
          </div>
        </div>
        <p className="memoContentTo">
          To: Executive Teams, HR Leadership, Well-being, Real Estate &
          Facilities
        </p>
        <div className="memoContent">
          <p className="subHeadingContent">Situation</p>
          <p>
            <strong>
              Poor employee engagement costs organisations on average £210 per
              employee per month. That’s about 6% of payroll.
            </strong>
          </p>
          <p className="extraMargin">
            Leading to unhappier teams, lower growth, decreased
            profitability…and poorer stakeholder returns. In a global
            environment where the challenges of a tighter labour market are only
            set to increase, companies cannot afford to have a workforce that is
            not flourishing. The link between employee flourishing and business
            performance has never been clearer.
          </p>
          <p className="subHeadingContent">Complication</p>
          <p>
            But organisations struggle to understand where the critical
            challenges really lie, and what is driving them…
          </p>
          <p>
            Hybrid working? Poor performance management? Lack of pay? Poor
            employee physical/mental health? And even if they do, they don’t
            know the highest value actions they can take to help their employees
            flourish in the workplace.
          </p>
          <p>
            <strong>
              Investing in interventions to enhance engagement across the
              workplace can feel like an untargeted piece of guesswork at best.
            </strong>
          </p>
          <p>
            Current solutions are not solving the problem effectively enough…
            More data is not helping.
          </p>
          <ul className="extraMargin">
            <li>Surveys with little or no action.</li>
            <li>Siloed systems.</li>
            <li>
              Not grounded in scientific research or statistical correlations.
            </li>
            <li>And failure to relate actions taken to real outcomes.</li>
          </ul>
          <p className="subHeadingContent">Resolution</p>
          <p>
            <strong>
              SHAPE is different…SHAPE helps you understand employee experience,
              and what they need to do to flourish.
            </strong>
          </p>
          <p>
            They connect your employee engagement directly to measurable root
            causes. This allows you to target your efforts, achieving change
            that drives real bottom line outcomes. Leading metrics like higher
            productivity, less absences, improved eNPS scores, increased talent
            retention. All measurably.
          </p>
          <p>
            <strong>
              Overcoming the challenges of our times. For your employees and for
              your business.
            </strong>
          </p>
        </div>
        <p className="memoReference">
          1. The Engagement Institute (A collaboration between The Conference
          Board, Sirota-Mercer, Deloitte, ROI)
        </p>
        <div className="memoBottomShare">
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 100 }}
            overlay={tooltipElem()}
            trigger={["click", "focus"]}
            rootClose={true}
          >
            <img src={isSafariBrow ? ShareSafari : Share} alt="Share" />
          </OverlayTrigger>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Memo

import React from "react"
import "./wepioneer.scss"
import Banner from "../../images/about/wepioneer.svg"
import BannerMob from "../../images/about/wepioneer-mob.svg"

export const WePioneer = () => {
  return (
    <div className="wepioneerWrapper">
      <div className="wepioneerContent">
        <h1>
          We're Pioneering
          <br /> the Science of Flourishing
        </h1>
        <p>
          On a mission to decode productivity at work, we made a remarkable
          discovery.
          <br /> <b>Flourishing</b> is the super-metric that powers
          productivity, engagement, commitment, and countless other drivers, but
          most organisations are in a <b>flourishing deficit.</b>
          <br />
          <br />
          So we made the instrument that helps organisations address this
          deficit, by helping them build a flourishing mindset, so they can
          flourish up to 10x faster.
        </p>
        <div className="wepioneerImg">
          <img src={Banner} alt="Pioneering" className="wepioneerImgDesktop" />
          <img src={BannerMob} alt="Pioneering" className="wepioneerImgMob" />
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import "./shapeTeam.scss"
import Link from "gatsby-link"
import { founders, team } from "./helper"
import Memo from "../overcoming-challenges/components/memo/memo"
import Ali from "../../images/about/shapeTeam/ali.png"
import John from "../../images/about/shapeTeam/john.png"
import Doug from "../../images/about/shapeTeam/doug2.jpg"
import Ken from "../../images/about/shapeTeam/ken.png"
import Craig from "../../images/about/shapeTeam/craig.jpg"
import Nicola from "../../images/about/shapeTeam/nicola.jpg"
import Gordon from "../../images/about/shapeTeam/gordon.jpg"
import LinkedIn from "../../images/about/linkedin.svg"

const TeamMap = {
  ali: Ali,
  john: John,
  ken: Ken,
  gordon: Gordon,
  nicola: Nicola,
  craig: Craig,
  doug: Doug,
}
const ShapeTeam = () => {
  const [openMemo, setOpenMemo] = useState(false)
  useEffect(() => {
    if (typeof window === "undefined") return
    if (window.location.hash === "#memo") {
      setTimeout(() => {
        setOpenMemo(true)
      }, 1000)
    }
  }, [])
  let getTeamElems = (data) =>
    data?.map((founder, index) => (
      <div key={`${founder.name}-${index}`} className="shapeTeamFounderCard">
        <div className="shapeTeamFounderHeaderFlex">
          <div className="shapeTeamFounderImgWrap">
            <img src={TeamMap[founder.img]} alt={founder.name} />
          </div>
          <div>
            <h3>{founder.name}</h3>
            <p>{founder.designation}</p>
          </div>
        </div>
        <p className="shapeTeamFounderDesc">{founder.desc}</p>
        <div className="shapeTeamLinkWrap">
          <a href={founder.linkedIn} target="_blank">
            <img
              src={LinkedIn}
              alt="LinkedIn"
              className="shapeTeamFounderLinkedIn"
            />
          </a>
          {founder?.memo && (
            <a className="shapeTeamLinkMemo" onClick={() => setOpenMemo(true)}>
              Read My Memo
            </a>
          )}
        </div>
      </div>
    ))

  return (
    <>
      <div className="shapeTeamWrapper">
        <div className="shapeTeamFlex">
          <div className="shapeTeamDescFlex">
            <h3>Our Team</h3>
            <p>
              We specialise in delivering hands-on organisational change through
              people. We help companies understand the art and science of
              flourishing. Through our work, we inspire actions that create
              sustainable behaviour change.
              <br />
              <br /> We deliver the unification of data intelligence most needed
              to enhance productivity, drive employee value and simply flourish
              in every facet of work.
            </p>
          </div>
          <div className="shapeTeamFoundersFlex">{getTeamElems(founders)}</div>

          {/* <hr />
        <div className="shapeTeamPressFlex">
          <h2>Read the press release</h2>
          <Link to="/press-release/behavioural-science-start-up-launches-to-help-international-businesses-solve-workplace-productivity-puzzle/">
            Press Release
          </Link>
        </div> */}
        </div>
      </div>
      {/*<div className="shapeTeamWrapper shapeAdvisorWrapper">
        <h1> Advisory Board Members</h1>
        <div className="shapeTeamFlex">
          <div className="shapeTeamFoundersFlex">{getTeamElems(team)}</div>
        </div>
      </div> */}
      <Memo openMemo={openMemo} setOpenMemo={setOpenMemo} />
    </>
  )
}

export default ShapeTeam
